<template>
  <div class="ToggleBar">
    <slot></slot>
  </div>
</template>

<script setup lang="ts"></script>

<style scoped lang="scss">
.ToggleBar {
  display: flex;
  padding: 3px;
  border: 1px solid var(--gray-5);
  background: var(--gray-7);
  border-radius: 13px;
  width: fit-content;
}
</style>
